import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { WebsocketService } from './services/websocket.service';
import * as $ from "jquery";
import { GlobalService } from './services/global.service';
export { VERSION } from '../environments/version'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @HostListener('window:unload', ['$event'])
  @HostListener('window:load', ['$event'])

  loadHandler(event) {
    this.initializeApp();
  }

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    if (this.authService.currentUserValue != null) {
      let user = this.authService.currentUserValue;
      this.globalService.changeAppTheme(user);

      document.documentElement.style.setProperty('--primary', user.color);
    }
  }


  initializeApp() {
    $(document).ready(function () {
      this.splashScreen.hide();
    })
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
    });
  }

  opensweetalert() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      timer: 10000
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )

      } else {
        // this.authService.logout();
      }
    });
  }


}
