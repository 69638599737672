import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { catchError, tap } from 'rxjs/operators';
import { Card } from '../models/interfaces/card';
import { User } from '../models/classes/user/user';
import * as $ from "jquery";

@Injectable({
  providedIn: 'root'
})
export class BankService {


  public payment_methods_subject: BehaviorSubject<any>;
  public payment_methods;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    let methods = localStorage.getItem('payment_methods') ?  JSON.parse(localStorage.getItem('payment_methods')) : null;
    this.payment_methods_subject = new BehaviorSubject<Card[]>(methods);
  }

  public get all_payment_methods(): Card[] {
    return this.payment_methods_subject.value;
  }

  getPaymentMethods(user = null) {
    let parametres = new HttpParams().set('userId', user ? user.id : this.authService.currentUserValue.id);

    let apiUrl = environment.apiUrl + "app/users/payment-methods/find";
    console.log("Call to " + apiUrl);
    return this.httpClient.get<Card[]>(apiUrl, { params: parametres })
      .pipe(
        tap(async (cards: Card[] | []) => {
          if(!cards) {
            cards = [];
          }
          localStorage.setItem('payment_methods', JSON.stringify(cards));
          this.payment_methods_subject.next(cards);
  
          return cards;
        }),
        catchError(this.handleError<Card[]>('getPaymentMethods', []))
    );
  }

  createPaymentIntent(stripeId, enterprise) {
    let parameters = new HttpParams()
      .set('amount', enterprise)
      .set('stripeId', stripeId);

    let apiUrl = environment.apiUrl + "payment-intent";
    console.log("Call to " + apiUrl);
    return this.httpClient.get<any>(apiUrl, {params: parameters});
  }

  verifyPayment(intent, key){
    return $.ajax({
      type : "POST",
      url: environment.apiUrl + "verify/payment/intent",
      data: {intent: intent, stripeKey: key}
    });
  }

  async checkPaymentsMethods(user:User, callback) {
    await this.getPaymentMethods(user).subscribe(
      async (cards) => {
        if (cards != null && cards.length > 0) {
          callback(true);
        } else {
          callback(false);  
        }
      }
    );
  }

  capturePaymentIntent(amount) {
    let paymentIntentId = null;
    try {
      paymentIntentId = sessionStorage.getItem('payment-intent-id');
    } catch (error) {
      throw new Error("Undefined payment intent id");
    }
    let params = new FormData();

    params.append('amount', amount);
    params.append('paymentIntentId', paymentIntentId);

    return this.httpClient.post<any>(environment.apiUrl + "payment-intent/capture", params);
  }

  saveNewCard() {
    return $.ajax({
      type : "POST",
      url: environment.apiUrl + "saveCard",
      data: {userId: this.authService.currentUserValue.id}
    });
  }

  payment_process(data) {

    let paymentObj = new FormData(
    );
    paymentObj.append("idStripe", data.idStripe);
    paymentObj.append("amount", data.amount);
    paymentObj.append("pm", data.pm);

    return this.httpClient.post<any>(environment.apiUrl + "payment", paymentObj).pipe(
      tap(user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.authService.currentUserSubject.next(user);
        }
      )

    );

  }

  deletePaymentMethod(payment_method) {

    this.httpClient.get(environment.apiUrl + "users/payment_methods/delete/" + payment_method).subscribe(cards => {
      localStorage.setItem('payment_methods', JSON.stringify(cards));
      this.payment_methods_subject.next(cards);
      this.globalService.openToast("Votre carte a bien été supprimée", "success");

      return cards;
    }, async (response) => {
      console.log('Delete payment error: ', response);
      if(response.error.message !== undefined){
        this.globalService.openToastError(response.error.message);
      }else if(response.error !== undefined){
        this.globalService.openToastError(response.error);
      }else if(response.status == 200){
        this.globalService.openToastSuccess(response.statusText);
      }
    });
  }

  setPreferedPaymentMethod(payment_method){
    let data = new FormData();
    data.append("userId", this.authService.currentUserValue.id.toString());
    data.append("payment_method", payment_method)
    return this.httpClient.post<any>(environment.apiUrl + "users/payment_methods/setPrefered", data).pipe(
      tap(
        (success) => {
          this.globalService.openToast("Moyen de paiment mis à jour", "success");
          return (success) // stripe pm_id
        })
      )
  }

  checkPreferredPaymentMethod(userId){
    let data = new FormData();
    data.append("userId", userId);
    return this.httpClient.post<any>(environment.apiUrl + "users/payment_methods/checkPrefered", data)
  }

  getStripeKey(idStripe){
    let data = new FormData();
    data.append("stripeId", idStripe);
    console.log('searching stripe key')
    return this.httpClient.post<any>(environment.apiUrl + "getStripeKey", data)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed: ${error.message}`); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
